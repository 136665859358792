export default {
    getProducts(state) {
        return state.products
    },
    getBrands(state) {
      return state.brands
    },
    getEditableProducts(state) {
      return state.editableProducts
    },
}