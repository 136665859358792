import productsEndpoints from "@/store/endpoints/productsEndpoints";
import {setTablePagination} from "@/utils/paginator";

export default {
  async getProducts(context, params) {
    const response = await productsEndpoints.getProducts(params)
    if (response.status === 200) {
      context.commit('loadProducts', response.data.data);
      setTablePagination(context, response.data)

      return response.data.data
    }
  },
  async getProductData(context, id) {
    const response = await productsEndpoints.getProductData(id)
    return response.data.data[0]
  },
  async getEditableProducts(context) {
    const response = await productsEndpoints.getProducts({
      isEditable: true
    })
    if (response.status === 200) {
      context.commit('loadEditableProducts', response.data.data)
    }
  },
  async getBrands(context) {
    const response = await productsEndpoints.getBrands()
    if (response.status === 200) {
      context.commit('loadBrands', response.data)
    }
  },

  async createProduct(context, data) {
    const response = await productsEndpoints.createOrEditProduct(data)
    return response.data
  },
  async editProduct(context, data) {
    return await productsEndpoints.createOrEditProduct(data.item)
  },
  async updateProductPanelType(context, data) {
    const params = {
      productId: data.productId,
      panelType: data.panelType.value
    }
    const response = await productsEndpoints.updateProductPanelType(params)

    if (response.status === 200) {
      context.commit('updateProductType', data)
    }
  },
  async deleteProduct(context, index) {
    let item = context.state.products[index]
    return await productsEndpoints.deleteProduct(item.id) // Passing Product UUID
  },

  async createOrEditBrand(context, data) {
    const response = await productsEndpoints.createOrEditBrand(data.brand)
    if (response.status === 201) {
      if (data.index > -1) {
        context.commit('uploadBrand', data)
      } else {
        context.commit('addBrand', data.brand)
      }
    }
    return response
  },
  async deleteBrand(context, index) {
    const brand = context.state.brands[index]
    const response = await productsEndpoints.deleteBrand(brand.id)
    if (response.status === 200) context.commit('removeBrand', index)
  }
}