import reportOutputResolver from "@/utils/reportOutputResolver";
import reportsEndpoints from "@/store/endpoints/reportsEndpoints";

export default {
  // Sales
  async getCommissions(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
      },
      endpoint: '/commissions_report',
    }
    await getReport(data)
  },
  async getCustomersBySeller(context, report) {
    const data = {
      params: {
        format: report.format,
      },
      endpoint: '/customers_by_seller_report',
    }
    await getReport(data)
  },
  async getPriceList(context, report) {
    const data = {
      params: {
        format: report.format,
        priceListId: report.priceListId,
      },
      endpoint: '/price_list_report',
    }
    await getReport(data)
  },
  async getPriceListByCustomer(context, report) {
    const data = {
      params: {
        format: report.format,
        customerId: report.customerId,
      },
      endpoint: '/price_list_by_customer_report',
    }
    await getReport(data)
  },
  async getSalesBook(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
      },
      endpoint: '/sales_book_report'
    }
    await getReport(data)
  },
  async getSalesByProvince(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
        provinceId: report.provinceId
      },
      endpoint: '/sales_by_province_report'
    }
    await getReport(data)
  },
  async getSalesRankingReport(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
      },
      endpoint: '/sells_ranking_report'
    }
    await getReport(data)
  },
  async getSellsByFamilySubfamily(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
        familyId: report.familyId,
        subfamilyId: report.subfamilyId,
      },
      endpoint: '/sells_by_family_subfamily_report'
    }
    await getReport(data)
  },
  async getCustomerCASummary(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
        currentAccountId: report.currentAccountId,
      },
      endpoint: '/customer_current_account_summary_report'
    }
    await getReport(data)
  },
  async getCollectionsByPeriod(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
      },
      endpoint: '/collection_invoice_by_period_report'
    }
    await getReport(data)
  },
  async getSalesByCustomerAndPeriod(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
        customerId: report.customerId,
        productId: report.productId
      },
      endpoint: '/sales_by_customer_and_period_report'
    }
    await getReport(data)
  },
  async getDebtorBalanceReport(context, report) {
    const data = {
      params: {
        limitDate: report.limitDate,
        format: report.format,
      },
      endpoint: '/debtor_balance_report'
    }
    await getReport(data)
  },

  // Suppliers
  async getSupplierCASummary(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
        currentAccountId: report.currentAccountId,
      },
      endpoint: '/supplier_current_account_report'
    }
    await getReport(data)
  },
  async getPurchasesBook(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
      },
      endpoint: '/purchases_book_report'
    }
    await getReport(data)
  },
  async getPaymentOrders(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
      },
      endpoint: '/payment_order_report'
    }
    await getReport(data)
  },
  async getAccumulatedByImputationReport(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
      },
      endpoint: '/accumulated_by_imputation_report'
    }
    await getReport(data)
  },
  async getACreditorBalanceReport(context, report) {
    const data = {
      params: {
        limitDate: report.limitDate,
        format: report.format,
      },
      endpoint: '/creditor_balance_report'
    }
    await getReport(data)
  },

  // Purchasing
  async getPurchasedProducts(context, report) {
    const data = {
      params: {
        format: report.format,
        supplierId: report.supplierId,
        productId: report.productId,
        from: report.from,
        to: report.to,
      },
      endpoint: '/purchased_products_report',
    }
    await getReport(data)
  },

  // Banks
  async getBankMovementHistory(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
        bankAccountId: report.bankAccountId,
        bankConceptId: report.bankConceptId,
      },
      endpoint: '/bank_movement_history_report',
    }
    await getReport(data)
  },
  async getThirdPartyChecksInventory(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
      },
      endpoint: '/third_party_checks_inventory_report',
    }
    await getReport(data)
  },
  async getThirdPartyChecksDelivered(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
      },
      endpoint: '/third_checks_deposited_delivered_report',
    }
    await getReport(data)
  },
  async getOwnChecksDelivered(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
      },
      endpoint: '/own_checks_delivered_report',
    }
    await getReport(data)
  },

  // Customer orders
  async getOrdersByCustomerAndPeriod(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
        customerId: report.customerId,
        productId: report.productId,
        valued: report.valued,
      },
      endpoint: '/customer_order_report',
    }
    await getReport(data)
  },

  // production
  async getOrdersBySector(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
        customerId: report.customerId,
        sectorId: report.sectorId,
      },
      endpoint: '/orders_by_sector_report',
    }
    await getReport(data)
  },

  async getOrdersByCustomer(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
        customerId: report.customerId,
      },
      endpoint: '/orders_by_customer_report',
    }
    await getReport(data)
  },
  async getOrdersHistoryByProduct(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
        productId: report.productId,
      },
      endpoint: '/orders_history_by_product_report',
    }
    await getReport(data)
  },

  // Stock
  async getStockMovementHistory(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
        productId: report.productId,
      },
      endpoint: '/stock_movement_history_report',
    }
    await getReport(data)
  },

  // Quality
  async getRG010Form() {
    const data = {
      endpoint: '/rg_010_form',
    }
    await getReport(data)
  },
  async getRG010ByOTReport(context, report) {
    const data = {
      params: {
        format: report.format,
        productionOrderId: report.productionOrderId,
      },
      endpoint: '/rg_010_by_ot_report',
    }
    await getReport(data)
  },
  async getRG010ByPeriodReport(context, report) {
    const data = {
      params: {
        format: report.format,
        from: report.from,
        to: report.to,
      },
      endpoint: '/rg_010_by_period_report',
    }
    await getReport(data)
  },
  async getRG014Form(context) {
    const data = {
      endpoint: '/rg_014_form',
    }
    await getReport(data)
  },
  async getProductTracingReport(context, report) {
    const data = {
      params: {
        format: report.format,
        correlativeNumber: report.correlativeNumber,
      },
      endpoint: '/product_tracing_report',
    }
    await getReport(data)
  },

}

async function getReport(data) {
  const response = await reportsEndpoints.getReport(data)
  if (response.status === 200) reportOutputResolver.openFile(response)
}