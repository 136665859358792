import axios from "axios";
import {host} from "@/store/config";

export default {
    async getExchangeParameters(params) {
        return await axios.get(host + '/exchange_parameter', {
            params
        })
    },
    async getPricesLists(params) {
        return await axios.get(host + '/price_lists', {
            params: params
        })
    },
    async getPriceListDetails(params) {
        return await axios.get(host + '/price_list_detail', {
            params: params
        })
    },
    async getThirdPartyChecks(params) {
        return await axios.get(host + '/third_party_checks', {
            params: params
        })
    },
    async getBanks(params) {
        return await axios.get(host + '/bank', {
            params: params
        })
    },
    async getBankAccounts(params) {
        return await axios.get(host + '/bank_account', {
            params: params
        })
    },
    async getBankConcepts(params) {
        return await axios.get(host + '/bank_concept', {
            params: params
        })
    },
    async getBankMovements(params) {
        return await axios.get(host + '/bank_movement', {
            params: params
        })
    },
    async getBankCards() {
        return await axios.get(host + '/card')
    },
    async getWallets() {
        return await axios.get(host + '/wallet')
    },
    async getPaymentsSchedule(limitDate) {
        return await axios.get(host + '/payments_schedule_searcher', {
            params: {
                limitDate: limitDate
            }
        })
    },
    async getOwnChecksSchedule(limitDate) {
        return await axios.get(host + '/own_check_schedule_searcher', {
            params: {
                limitDate: limitDate
            }
        })
    },
    async getPerceptionTxt(perceptionData) {
        return await axios.get(host + '/perception_txt', {
            params: {
                from: perceptionData.from,
                to: perceptionData.to,
            },
            responseType: 'arraybuffer',
        })
    },

    async saveExchangeRateParameter(exchange) {
        return await axios.put(host + '/exchange_parameter', exchange)
    },
    async deleteExchangeRateParameter(id) {
        return await axios.delete(host + '/exchange_parameter', {
            params: {
                id
            }
        })
    },

    async createOrEditPricesList(list) {
        return await axios.put(host + '/price_list', list)
    },
    async deletePricesList(id) {
        return await axios.delete(host + '/price_list', {
            params: {
                id: id,
            }
        })
    },
    async createOrEditPriceListDetail(detail) {
        return await axios.put(host + '/price_list_detail', detail)
    },
    async removeDetailFromList(id) {
        return await axios.delete(host + '/price_list_detail', {
                params: {
                    id: id,
                }
            }
        )
    },

    async createOrEditBank(bank) {
        return await axios.put(host + '/bank', bank)
    },
    async deleteBank(id) {
        return await axios.delete(host + '/bank', {
            params: {
                id: id,
            }
        })
    },
    async createOrEditBankAccount(bankAccount) {
        return await axios.put(host + '/bank_account', bankAccount)
    },
    async deleteBankAccount(id) {
        return await axios.delete(host + '/bank_account', {
            params: {
                id: id,
            }
        })
    },

    async createOrEditBankConcept(concept) {
        return await axios.put(host + '/bank_concept', concept)
    },
    async separateBankConceptAccount(accountId) {
        return await axios.put(host + '/bank_concept_account_separate', null, {
            params: {
                accountId
            }
        })
    },
    async deleteBankConcept(id) {
        return await axios.delete(host + '/bank_concept', {
            params: {
                id: id,
            }
        })
    },

    async createOrEditBankMovement(data) {
        return await axios.put(host + '/bank_movement', data)
    },
    async deleteBankMovement(id) {
        return await axios.delete(host + '/bank_movement', {
            params: {
                id: id,
            }
        })
    },

    async createOrEditCard(card) {
        return await axios.put(host + '/card', card)
    },
    async deleteBankCard(id) {
        return await axios.delete(host + '/card', {
            params: {
                id: id,
            }
        })
    },

    async createOrEditWallet(wallet) {
        return await axios.put(host + '/wallet', wallet)
    },
    async deleteWallet(id) {
        return await axios.delete(host + '/wallet', {
            params: {
                id: id,
            }
        })
    }
}