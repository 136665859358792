import store from "@/store";

export function loadBaseResources() {
    if (store.getters['app/getIfAuthenticated']) {
        store.dispatch('app/getSectorsByUser')
        store.dispatch('app/getParameters')
        store.dispatch('customers/getCustomers')
        store.dispatch('suppliers/getSuppliers')
        store.dispatch('people/getProvinces')
        store.dispatch('products/getEditableProducts')
    }
}