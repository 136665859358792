export default {
    loadProducts(state, products) {
        state.products = products
    },
    loadBrands(state, brands) {
        state.brands = brands
    },
    loadEditableProducts(state, products) {
        state.editableProducts = products
    },

    addBrand(state, brand) {
        state.brands.unshift(brand)
    },
    uploadBrand(state, data) {
        Object.assign(state.brands[data.index], data.brand)
    },
    updateProductType(state, data) {
        state.products[data.index].type = data.panelType.text
    }
}