export default {
  loadExchangeParameters(state, exchanges) {
    state.exchangeRateParameters = exchanges
  },
  loadPricesLists(state, pricesLists) {
    state.pricesLists = pricesLists
  },
  loadProductsInList(state, details) {
    state.pricesListDetails = details
  },
  loadBanks(state, banks) {
    state.banks = banks
  },
  loadBankAccounts(state, bankAccounts) {
    state.bankAccounts = bankAccounts
  },
  loadBankConcepts(state, concepts) {
    state.bankConcepts = concepts
  },
  loadBankMovements(state, bankMovements) {
    state.bankMovements = bankMovements
  },
  loadBankCards(state, cards) {
    state.bankCards = cards
  },
  loadWallets(state, wallets) {
    state.wallets = wallets
  },

  removeExchangeRateParameter(state, index) {
    state.exchangeRateParameters.splice(index, 1)
  },

  addBankCard(state, card) {
    state.bankCards.unshift(card)
  },
  removeBankCard(state, index) {
    state.bankCards.splice(index, 1)
  },

  addWallet(state, wallet) {
    state.wallets.unshift(wallet)
  },
  removeWallet(state, index) {
    state.wallets.splice(index, 1)
  },
}