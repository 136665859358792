export default {
  getCustomers(state) {
    return state.customers
  },
  getFilteredCustomers: (state) => (searchStr, type) => {
    if (type === 'socialReason') {
      return state.customers.filter((item) => 
        item.socialReason.toLowerCase().includes(searchStr.toLowerCase())
      )
    }
    if (type === 'documentNumber') {
      return state.customers.filter((item) => {
          if (item.documentNumber)
            return item.documentNumber.toLowerCase().includes(searchStr.toLowerCase())
        }
      )
    }
  },
  getTransports(state) {
    return state.transports
  },
  getContactChannels(state) {
    return state.contactChannels
  },
  getPricesLists(state) {
    return state.pricesLists
  },
  getCustomerCurrentAccountDetails(state) {
    return state.customerCurrentAccountDetails
  },
  getCustomerProofsForImputation(state) {
    return state.customerProofsForImputation
  },
  getCustomerProofsForCompensation(state) {
    return state.customerProofsForCompensation
  },
  getFilteredCustomerProofsForCompensation(state) {
    return search => state.customerProofsForCompensation.filter(
      proof => proof.description.replace('É', 'E').toLowerCase().includes(search.toLowerCase())
    )
  },
  getCollectionProofsForImputation(state) {
    return state.collectionProofsForImputation
  },
  getFilteredCollectionProofsForImputation(state) {
    return search => state.collectionProofsForImputation.filter(
      proof => proof.description.replace('É', 'E').toLowerCase().includes(search.toLowerCase())
    )
  },
  getCollectionsAndCreditNotes(state) {
    return state.collectionsAndCreditNotes
  },
  getFilteredCollectionsAndCreditNotes(state) {
    return search => state.collectionsAndCreditNotes.filter(
        proof => proof.collectionNumber.replace('É', 'E').toLowerCase().includes(search.toLowerCase()) ||
        proof.creditNoteNumber.replace('É', 'E').toLowerCase().includes(search.toLowerCase())
    )
  },
  getImputations(state) {
    return state.imputations
  },
  getSellers(state) {
    return state.sellers
  },
  getQuotations(state) {
    return state.quotations
  },
  getCustomersWithCreditLimit(state) {
    return state.customersWithCreditLimit
  }
}