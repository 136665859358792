import stockEndpoints from "@/store/endpoints/stockEndpoints";
import reportsEndpoints from "@/store/endpoints/reportsEndpoints";
import {HttpStatusCode} from "axios";
import reportOutputResolver from "@/utils/reportOutputResolver";
import {setTablePagination} from "@/utils/paginator";

export default {
    async getStockConcepts(context, params) {
        const response = await stockEndpoints.getStockConcepts(params)
        if (response.status === 200) {
            context.commit('loadStockConcepts', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getDeposits(context) {
        const response = await stockEndpoints.getDeposits()
        if (response.status === 200) context.commit('loadDeposits', response.data)
    },
    async getPurchaseOrdersDetailPendingEntry(context, productId) {
        const response = await stockEndpoints.getPurchaseOrdersDetailPendingEntry(productId)
        if (response.status === 200) context.commit('loadPurchaseOrderDetailPendingEntry', response.data)
    },
    async getStockMovementByOrigin(context, params) {
        const response = await stockEndpoints.getStockMovementByOrigin(params)
        if (response.status === 200) {
            context.commit('loadStockMovementDetails', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getStockMovementReport(context, id) {
        const data = {
            params: {
                stockMovementDetailId: id,
            },
            endpoint: '/stock_entry_report'
        }
        const response = await reportsEndpoints.getReport(data)

        if (response.status === HttpStatusCode.Ok) reportOutputResolver.openFile(response)
    },
    async getStockTransportDetails(context, params) {
        const response = await stockEndpoints.getStockTransportDetails(params)
        if (response.status === 200) {
            context.commit('loadStockTransportDetails', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getWarehouses(context) {
        const response = await stockEndpoints.getWarehouses()
        if (response.status === 200) context.commit('loadWarehouses', response.data.data)
    },

    async createOrEditStockConcept(context, concept) {
        const response = await stockEndpoints.createOrEditStockConcept(concept)
        if (response.status === 201) context.commit('addStockConcept', concept)
        return response
    },
    async deleteStockConcept(context, index) {
        const concept = context.state.stockConcepts[index]
        const response = await stockEndpoints.deleteStockConcept(concept.id)
        if (response.status === 200) context.commit('removeStockConcept', index)
        return response
    },
    async createStockMovement(context, movement) {
        return await stockEndpoints.createStockMovement(movement)
    },
    async deleteStockMovementDetail(context, index) {
        const stockMovementDetail = context.state.stockMovementDetails[index]
        return await stockEndpoints.deleteStockMovementDetail(stockMovementDetail.id)
    },
    async createOrEditWarehouse(context, warehouse) {
        return await stockEndpoints.createOrEditWarehouse(warehouse)
    },
    async createStockTransport(context, transport) {
        return await stockEndpoints.createStockTransport(transport)
    }
}