export default {
    getViewTitle(state) {
        return state.viewTitle
    },
    getUser(state) {
      return state.user
    },
    getRole(state) {
      return state.role
    },
    getSector(state) {
      return state.sector
    },
    getAvatarSrc(state) {
      return state.avatarSrc
    },
    getIfAuthenticated(state) {
      return state.authenticated
    },
    getParameters(state) {
        return state.appParameters
    },
    getSectorsByUser(state) {
        return state.sectorsByUser
    },
    getFromSectorIds(state) {
        return state.fromSectorIds
    },
    getToSectorIds(state) {
        return state.toSectorIds
    },

    // Pagination
    getLoadingTable(state) {
        return state.loadingTable
    },
    getTableOptions(state) {
        return state.tableOptions
    },
    getPage(state) {
        return state.page
    },
    getItemsPerPage(state) {
        return state.itemsPerPage
    },
    getItemsPerPageOptions(state) {
        return state.itemsPerPageOptions
    },
    getTotalItems(state) {
        return state.totalItems
    },
    getTotalPages(state) {
        return state.totalPages
    },
    getLastPaginatedEndpoint(state) {
        return state.lastPaginatedEndpoint
    }
}